#formId{
    text-align: left;
}

/* .btn-success{
    margin: 3px;
} */

/* #dialog-regist{
    display: none;
} */

/* #closeBtn{
    display: none;
} */
